import {authAwareFetch} from "./utilities";

export const sessionExists = () => {
    return authAwareFetch(process.env.REACT_APP_CE_HTTP_DOMAIN + "/chat-engine/session", {
        method: "GET",
        credentials: "include"
    })
    .then(res => {
        if (res.status === 401) {
            window.alert("Auth expired!");
        }
        return res.ok && res.status === 204;
    })
    .catch(err => false);
};
export const sessionCreate = () => {
    return authAwareFetch(process.env.REACT_APP_CE_HTTP_DOMAIN + "/chat-engine/session", {
        method: "POST",
        credentials: "include"
    });
};
export const sessionDelete = () => {
    return fetch(process.env.REACT_APP_CE_HTTP_DOMAIN + "/chat-engine/session", {
        method: "DELETE",
        credentials: "include"
    });
};